import React from 'react';
import transIcon from '../assets/img/icon/trans.svg';
import decentralizedIcon from '../assets/img/icon/decentralized.svg';
import saveIcon from '../assets/img/icon/save.png';

const Features = ({ className }) => {
  return (
    <div id="Tron10x" className={className}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-5 mb-3 default-title">
            <h3 className='text-white'>Why Choose <span className='fw-bold text-white'>Tron10x</span> Platform?</h3>
          </div>
          <div className="col-md-4 tron-box">
            <div className="tron-min">
              <img src={transIcon} width="55" alt="" />
              <h4>TRANSPARENCY</h4>
              <hr />
              <p className="mb-0">
                You can transparently view all transactions and details that have been made since the
                date the Smart Contract was created.
              </p>
            </div>
          </div>

          <div className="col-md-4 tron-box">
            <div className="tron-min">
              <img className="filter" src={decentralizedIcon} width="52" alt="" />
              <h4>DECENTRALIZED</h4>
              <hr />
              <p className="mb-0">
                Tron10x is not managed by anyone, including its own software team. It is developed as a
                fully automatic system. Nobody has access to funds. Your funds are secured between you
                and the smart contract.
              </p>
            </div>
          </div>

          <div className="col-md-4 tron-box">
            <div className="tron-min">
              <img src={saveIcon} width="47" alt="" />
              <h4>HIGH SECURITY</h4>
              <hr />
              <p className="mb-0">
                Smart contracts are a part of Blockchain technology. Blockchain is a secure technology
                that no hacker can access.
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Features;
