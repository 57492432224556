import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import telegramIcon from "../assets/img/icon/paper-plane.png";
import youtubeIcon from "../assets/img/icon/youtube.png";
import emailIcon from "../assets/img/icon/dots.png";
// import robotIcon from "../assets/img/icon/robot.png";
import logoFooter from "../assets/img/logo-footer.png";
import robot from "../assets/img/icon/robot.png";

const Footer = () => {
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    const copyContractAddress = () => {
      const contractId = document.getElementById("contractId");
      if (contractId) {
        navigator.clipboard.writeText(contractId.innerText);
        alert("Contract address copied to clipboard: " + contractId.innerText);
      }
    };

    const copyReferralLink = () => {
      const copyText = document.getElementById("reflinkdisplay");
      if (copyText) {
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        alert("Copied: " + copyText.value);
      }
    };

    const copyContractBtn = document.getElementById("copyContractBtn");
    const copyReferralBtn = document.getElementById("copyReferralBtn");

    if (copyContractBtn) {
      copyContractBtn.addEventListener("click", copyContractAddress);
    }

    if (copyReferralBtn) {
      copyReferralBtn.addEventListener("click", copyReferralLink);
    }

    return () => {
      if (copyContractBtn) {
        copyContractBtn.removeEventListener("click", copyContractAddress);
      }
      if (copyReferralBtn) {
        copyReferralBtn.removeEventListener("click", copyReferralLink);
      }
    };
  }, []);

  return (
    <footer
      onMouseEnter={() => setFlag(true)}
      onMouseLeave={() => setFlag(false)}
      style={
        flag
          ? {
              backgroundImage:
                "linear-gradient(115deg, #2f323b 0%, #2748b4 100%)",
            }
          : {}
      }
    >
      
      <div className="container">
        <div className="row">
          {/* <ul className="social">
                    <li>
                        <div className="col-md-4 footer-box text-right" style={{ alignSelf: "center" }}>
                            <div className="chat_wrapper" style={{ float: "left" }}>
                                <Link to="https://t.me/Tron10x_Official" target="_blank" className="chat_box">
                                    <img src={telegramIcon} alt="Telegram" width={21} />
                                    <span className="text">Tron10x Chat</span>
                                </Link>
                            </div>
                        </div>
                    </li>
                </ul> */}
          <div className="col-md-4 footer-box" style={{ alignSelf: "center" }}>
            <a href="#" className="logo-mb hide-mb">
              <img src={logoFooter} className="img-fluid" alt="" />
            </a>
            <ul className="social">
              <li>
                <Link
                //  to="https://t.me/Tron10x_Official" target="_blank"
                 >
                  <span className="left-social">
                    <img src={telegramIcon} alt="Telegram" width={21} />
                  </span>
                  <span className="right-social">
                    Main Telegram Group:
                    <strong>Tron10xPro</strong>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="#" target="_blank">
                  <span className="left-social">
                    <img src={youtubeIcon} alt="YouTube" width="19" />
                  </span>
                  <span className="right-social">
                    Youtube Channel:
                    <strong>Tron10x</strong>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <span className="left-social">
                    <img src={emailIcon} alt="Email" width="18" />
                  </span>
                  <span className="right-social">
                    E-Mail Support:
                    <strong>info@Tron9x.pro</strong>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4 footer-box text-center">
            <a href="#" className="logo">
              <img src={logoFooter} className="img-fluid" alt="" />
            </a>
            <p className="copyright">
              © 2023-24 All Rights Reserved Tron10x.pro
            </p>
            <div className="contract-address">
              <a
                // href="https://tronscan.org/#/contract/TANxQQNNqX1R1PMcLz5W2cdvS6W4B6awdx/code"
                target="_blank"
                className="contract-link"
              >
                <span className="contract-id" id="contractId">
                  TANxQQNNqX1R1PMcLz5W2cdvS6W4B6awdx
                </span>
              </a>
              <button className="copy-btn" id="copyContractBtn">
                Copy
              </button>
            </div>
          </div>

          <div className="col-md-4 footer-box text-right align-self-center">
            <div className="chat_wrapper">
              <a
                // href="https://t.me/Tron10x_Official"
                target="_blank"
                className="chat_box"
              >
                <i className="fa fa-link"></i>
                <span className="text">Tron10x Chat</span>
                <span className="robocop">
                  <img src={robot} alt={`name is ${robot}`} />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="waves">
      <div class="wave" id="wave1"></div>
      <div class="wave" id="wave2"></div>
      <div class="wave" id="wave3"></div>
      <div class="wave" id="wave4"></div>
    </div>
    </footer>
  );
};

export default Footer;
