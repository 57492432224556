import React from 'react';


const Contribution = ({contribute , close}) => {
  return (
    <div className="modal " id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle2" style={contribute?{ paddingRight: '15px', display: 'block', paddingLeft: '15px' }:{}} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="modal-body">
            <div className="text-center">
              <h2>MAKE NEW CONTRIBUTION</h2>
              <p className="mt-4">Specify deposit <span className="balloon">TRX</span> amount here:</p>
            </div>
            <form action="#" method="post">
              <input type="text" className="form-control" id="trxValue1" defaultValue="1000" />
            </form>

            <span id="contributeAddresss" style={{ color: '#fff', display: 'flex' }}>Loading...<div className="loader"></div>
            </span>
            <div className="referral-link text-center" id="contributeAddress" style={{ display: 'none' }}> <a href="#" id="investButton1"><i className="fa fa-link"></i> CONTRIBUTION</a></div>

            <div className="what-lorem">
              <img src="assets/img/user%20(6).png" alt="" /> <span>upline partner wallet:</span><br />
              <a href="#" id="upline">No upline</a>
            </div>

            <hr />

            <ul className="lorem-ul">
              <li><i className="fa fa-warning"></i> <span>IMPORTANT!</span> Do not forget about blockchain fee! You should have <b>80-120 TRX</b> more on your wallet, or your transaction will get *out of energy* status!</li>
              <li><i className="fa fa-check"></i> Minimum contribution amount <span>100 TRX</span></li>
              <li><i className="fa fa-check"></i> We are working only with: <span>TRON (TRX)</span> cryptocurrency</li>
              <li><i className="fa fa-check"></i> Your contribution will be activated after <span>1 confirmation</span> in blockchain</li>
              <li><i className="fa fa-check"></i> Withdrawable rewards come instantly to your secure wallet balance, which is accessible only to You</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contribution;
