import React, { useEffect, useState } from "react";
import videoSource from "../assets/img/istocj video.mp4";
import videoImg from "../assets/img/video.png";
import PersonalWallet from "../models/PersonalWallet";
import Contribution from "../models/Contribution";
// import Particle from "../components/Partical";

const Header = ({ setMouseShow }) => {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [navbarFixed, setNavbarFixed] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [flexDirection, setFlexDirection] = useState("row");
  const [menushow, setMenuShow] = useState(false);
  const [flag, setFlag] = useState(false);
  const [contributeflag, setContributeflag] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false)

  useEffect(() => {
    setMouseShow(contributeflag || flag);
  }, [contributeflag, flag]);


  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrolledDown = currentScrollPos > prevScrollPos;

      setNavbarFixed(!isScrolledDown);
      setPrevScrollPos(currentScrollPos);

    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const notfixed = {
    position: "absolute",
    top: 0,
    zIndex: 999,
    transition: "top 0.3s",
    width: "100%",
    color: "#fff",
    left: 0,
  };

  const styles = {
    header: {
      position: "relative",
      width: "100vw",
      overflow: "hidden",
      backgroundImage: "none",
      zIndex: 999,
      margin: 0,
    },
    video: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      objectFit: "cover",
      backgroundRepeat: "no-repeat",
      width: "100vw",
      // maxHeight: "65%",
      zIndex: 0,
      backgroundImage:
        "url('https://img.freepik.com/free-vector/gradient-colored-network-connection-background_23-2148893498.jpg?size=626&ext=jpg')",
      display: scrollPosition > 50 ? "none" : "block",
      backgroundSize: "cover",
    },

    navbarFixed: {
      position: "fixed",
      top: 0,
      zIndex: 999,
      transition: "top 0.3s",
      width: "100vw",
      backgroundColor: "#fff",
      color: "#000",
      left: 0,
    },
    floatRight: {
      // float: 'right'
    },
  };

  const tabletMaxWidth = 768;
  const mobileMaxWidth = 576;

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia(`(max-width: ${tabletMaxWidth}px)`).matches) {
        setFlexDirection("column-reverse");
      } else {
        setFlexDirection("row");
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (window.matchMedia(`(max-width: ${tabletMaxWidth}px)`).matches) {
    styles.video.maxHeight = "100vh";
  }

  // if (window.matchMedia(`(max-width: ${mobileMaxWidth}px)`).matches) {
  //   styles.video.maxHeight = "35%";
  // }

  function toggleVideo() {
    const videoElement = document.getElementById("videoElement");
    const imgElement = document.querySelector(".video img");

    if (videoElement.style.display === "none") {
      // Show video, hide image
      videoElement.style.display = "block";
      imgElement.style.display = "none";
      videoElement.play();
      setVideoPlay(true)
    } else {
      // Hide video, show image
      videoElement.style.display = "none";
      imgElement.style.display = "block";
      videoElement.pause();
      videoElement.currentTime = 0;
      setVideoPlay(false)

    }
  }

  return (
    <>
      <PersonalWallet flag={flag} close={() => setFlag(false)} />
      <Contribution
        contribute={contributeflag}
        close={() => setContributeflag(false)}
      />
      <div id="mobileMenu" style={menushow ? { display: "block" } : {}}>
        <button
          onClick={() => setMenuShow(false)}
          className="closes bg-transparent shadow-0"
        >
          <i className="fa fa-times-circle-o"></i>
        </button>
        <div className="menu">
          <a className="normal-link scroll" href="#Tron10x">
            Project
          </a>
          <a className="normal-link  scroll" href="#papers">
            Plan
          </a>
          <a
            className="normal-link"
            data-toggle="modal"
            data-target="#exampleModal"
            href="#"
          >
            Wallet
          </a>
          <a
            className={`deposit ${prevScrollPos && "invert-filter"}`}
            data-toggle="modal"
            data-target="#exampleModal2"
            href="#"
            onClick={() => setContributeflag(true)}
          >
            Contribute
          </a>
        </div>
      </div>
      <header className="m-0 bg-secondary py-3 my-5" style={styles.header}>
        <div className="container bg-secondary">
          <div className="" style={styles.video} />
          <div className="row">
            <div
              className={`navbar row ${navbarFixed ? "navbar-fixed" : ""}`}
              id="topfix"
              style={navbarFixed ? styles.navbarFixed : notfixed}
            >
              <div className="col-md-3 col-sm-5 col-6 logo">
                <a
                  href="index.html"
                  style={{
                    display: "inline-block",
                    position: "relative",
                    zIndex: 99,
                  }}
                >
                  <h1 className="text-white orbitron">
                    TRON
                    <span
                      style={{
                        background:
                          "linear-gradient(to right, #e7922e, #ffc96e)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      4X
                    </span>
                  </h1>
                </a>
              </div>
              <div className="col-md-9 col-sm-7 col-6 collapses">
                <div className="float-right" style={styles.floatRight}>
                  <a className="normal-link scroll" href="#Tron10x">
                    Project
                  </a>
                  <a className="normal-link  scroll" href="#papers">
                    Plan
                  </a>
                  <a
                    className="normal-link"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    href="/"
                    onClick={() => setFlag(true)}
                  >
                    Wallet
                  </a>
                  <a
                    className={`deposit ${prevScrollPos && "invert-filter"}`}
                    data-toggle="modal"
                    data-target="#exampleModal2"
                    href="#"
                    onClick={() => setContributeflag(true)}
                  >
                    Contribute
                  </a>
                </div>
                <button
                  id="hamburger_btn"
                  type="button"
                  className="btn btn-outline-light btn-sm"
                  onClick={() => setMenuShow(true)}
                >
                  <i className="fa fa-bars"></i>
                </button>
              </div>
            </div>
            <div className="row mt-5" style={{ flexDirection: flexDirection }}>
             

              <div className="col-md-7 header-left">
                <h1
                  className="text-"
                  style={{
                    textTransform: "uppercase",
                    fontSize: "35px",
                    color: "#e7922e",
                  }}
                >
                  <span className="h-1" style={{    color: "inherit"}}>Launching the Next Generation</span>
                </h1>
                <h4 style={{ fontSize: "32px", color: "#fff" }}>
                  100% Decentralized Platform
                </h4>
                <p>
                  Tron10x represents the fusion of cutting-edge Digital
                  Technology, Robust Security, and Community-driven Initiatives.
                </p>
                
              </div>
              <div className="col-md-5 header-right ">
                <div className="video position-relative">
                  <img  className="img-fluid" src='' alt="" />
                   <a className="d-md-block d-none"  href="#" onClick={toggleVideo}> {!videoPlay ? (<i className="fa fa-play"></i>) : (<i className="mx-0 fa fa-pause"></i>)} 
                   <p style={{width:"10vw"}} className="fs-6 d-flex fw-bold text-center">Video Presentation</p>
                   </a>  
                 
                  <video className="img-fluid" id="videoElement" src={videoSource} controls muted style={{ display: "none" }}> </video>
                </div>
              </div>
              <div className="header-left row w-100  justify-content-between">
              <a
                  data-toggle="modal"
                  data-target="#exampleModal"
                  href="/"
                  className="btn-purple mx-1 col-5 col-md-2 "
                  onClick={() => setFlag(true)}
                >
                  Personal Wallet
                </a>
                <a
                  href="/"
                  data-toggle="modal"
                  data-target="#exampleModal2"
                  className="btn-white  mx-1 col-5 col-md-2"
                >
                  Contribution
                </a>
                <br /> <br />
                <a
                  // href="https://tronscan.org/#/contract/TANxQQNNqX1R1PMcLz5W2cdvS6W4B6awdx/code"
                  target="_blank"
                  className="btn-white  mx-1 col-5 col-md-2"
                >
                  {" "}
                  Smart Contract
                </a>
                <a
                  href="Tron10x.pdf"
                  target="_blank"
                  className="btn-purple  mx-1 col-5 col-md-2"
                  style={{ marginLeft: "10px" }}
                >
                  Explore Presentation
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <nav className="nav social-fixed" style={{ justifyContent: "center" }}>
        <a
          className="left-social  py-3 px-1  d-flex align-items-center justify-content-center text-white mb-2"
          // href="https://t.me/Tron10x_Official"
        >
          <i className="la la-telegram text-primary fa-lg" target="_blank"></i>
        </a>
      </nav>

      {/* <Particle/> */}
    </>
  );
};

export default Header;
