import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Notfound = () => {
  const [time, setTime] = useState(3);
  const navigation = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (time > 0) {
        setTime(prevTime => prevTime - 1);
      } else {
        navigation('/');
        window.location.reload(); 
      }
    }, 1500); 
    return () => clearTimeout(timer);
  }, [time, navigation]);

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center" style={{ height: '50vh' }}>
        <div className="col-md-6 text-center">
          <h2 style={{ fontWeight: 'bold', marginBottom: '20px' }}>
            Redirecting in <span style={{ color: '#e7922e' }}>{time}</span> seconds...
          </h2>
          {/* Optionally, you can add a button to go to the home page */}
          {/* <Link className='btn btn-secondary' to='/'>Go to Home Page</Link> */}
        </div>
      </div>
    </div>
  );
};

export default Notfound;
