import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import AnimatedCursor from "react-animated-cursor";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import "./custom/style.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/style.css";
import "./assets/css/style532d.css";
import "./assets/css/swiper.min.css";
import Features from "./components/Features";
import AuditReport from "./components/AuditReport";
import Works from "./components/Works";
import CommunityProgram from "./components/CommunityProgram";
import Models from "./components/Models";
import Affliated from "./components/Affliated";
import Notfound from "./page/Notfound";
// import Animation from './components/animation';

const App = () => {
  const [animationElements, setAnimationElements] = useState([]);
  const [mouseShow, setMouseShow] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const windowScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;

      animationElements.forEach((element) => {
        const elementTop = element.offsetTop;
        const elementHeight = element.offsetHeight;

        if (
          elementTop + elementHeight >= windowScrollTop &&
          elementTop <= windowScrollTop + windowHeight
        ) {
          element.classList.add("in-view");
        } else {
          element.classList.remove("in-view");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [animationElements]);

  useEffect(() => {
    const animationElements = document.querySelectorAll(".animation-element");
    setAnimationElements(animationElements);
  }, []);

console.log("mouseShow", mouseShow)
  return (
    <BrowserRouter>
       <Header setMouseShow={setMouseShow} />
      {/* <Animation/> */}
      <Routes>
        <Route
          path="/"
          element={
            <div className="animation-element" id="content">
              <div className="cursor__dot">
                <AnimatedCursor
                  innerSize={15}
                  outerSize={15}
                  color="30, 30 ,30"
                  outerAlpha={0.4}
                  innerScale={0.7}
                  outerScale={5}
                />
              </div>
              
              <Models className="animation-element slide-left" />
              <Features className="animation-element slide-left" />
              <AuditReport className="animation-element slide-right" />
              <Works className="animation-element slide-left" />
              <CommunityProgram className="animation-element slide-up" />
              <Affliated className="animation-element " />
            </div>
          }
        />
        <Route path="*" element={<Notfound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
