import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { nord } from "react-syntax-highlighter/dist/esm/styles/prism";
import codingImage from "../assets/img/coding.png";
import cubeIcon from "../assets/img/icon/cube.png";
import linkIcon from "../assets/img/link.png";
import logoImage from "../assets/img/logo-2.png";

const AuditReport = ({ className }) => {
  return (
    <div>
      <div id="code" className={className}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 mt-5 line-12">
              <div className="line"></div>
              <img src={cubeIcon} alt="" />
              <div className="line mr"></div>
            </div>
            <div className="col-md-11 my-3">
              <div className="row">
                <div className="col-md-5"></div>
                <div className="col-md-7 pl-0">
                  <div className="whats-text">
                    <h5>
                      Audit Report and Smart Contract Code <span></span>{" "}
                    </h5>
                    <p className="mb-0">
                      The codes of the smart contract have been audited by an
                      independent third party audit firm.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-2"/> */}
            <div className="col-md-10 mt-4 pl-0">
              <div className="report">
                <img className="codes" src={codingImage} alt="" />
                <div className="code-box">
                  <h4>
                    Audit Report <br />
                    and Smart Contract Code{" "}
                  </h4>
                  <div className="code-min">
                    <SyntaxHighlighter language="javascript" style={nord}>
                      {`contract Tron10x {
  uint constant public DEPOSITS_MAX = 500;
  uint constant public INVEST_MIN_AMOUNT = 100 trx;
  uint constant public INVEST_MAX_AMOUNT = 4000000 trx;
  uint constant public BASE_PERCENT = 100;
  uint[] public REFERRAL_PERCENTS = [1000, 400, 200, 100, 100, 50, 50, 40, 30, 20, 10];
  uint constant public MARKETING_FEE = 500;
}`}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <div className="card-link">
                  <img src={logoImage} alt="" />
                  <a href="#" target="_blank">
                    Audit Report
                  </a>
                  {/* <a href="#" target="_blank" >2-Audit Report</a>  */}
                </div>
              </div>
              <div className="whats-link">
                <div className="what-box">
                  <strong>Total Contributed Amount</strong>
                  <a href="#" className="not-click">
                    <span className="right-numb">
                      <span id="totalInvested1" className="number">
                        0
                      </span>
                      <b>
                        {" "}
                        Current Bonus : <i id="userPercentRate1"> 0%</i>
                      </b>
                    </span>
                    <img src={linkIcon} alt="" />
                  </a>
                </div>
                <div className="what-box mt-3">
                  <strong>Total Active Participants</strong>
                  <a href="#" className="not-click">
                    <span className="right-numb">
                      <span id="totalUsers" className="number numb-copy">
                        0
                      </span>
                      {/* <b>Current Bonus: <i id="userPercentRate1">0</i></b> */}
                    </span>
                    <img src={linkIcon} alt="" />
                  </a>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>

      <div id="lines">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-5 line-12">
              <div className="line"></div>
              <img src={cubeIcon} alt="" />
              <div className="line mr"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditReport;
