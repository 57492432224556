import React, { useState } from 'react';
import tronicon from '../assets/img/tronicon.png';
import item1 from '../assets/img/icon/item-1.png';
import item2 from '../assets/img/item-2.png';
import item3 from '../assets/img/icon/item-3.png';
import signal from '../assets/img/icon/signal.png';

const PersonalWallet = ({flag , close}) => {
  return (
    <div
    className="modal"
    id="exampleModal"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    style={flag ?{ display: 'block', paddingRight: '15px', paddingLeft: '15px' } :{}}
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={close}
        >
          <span aria-hidden="true">×</span>
        </button>
        <div className="modal-body">
          <div className="text-center">
            <h2>PERSONAL WALLET</h2>
            <p>
              Your <img src={tronicon} alt="" />
              <span>TRX</span> wallet address:
            </p>
          </div>
          <form action="#" method="post">
            <input
              className="form-control"
              id="walletAddress"
              placeholder="loading"
              readOnly=""
            />
          </form>
          <div className="item-boxes">
            <div className="row">
              <div className="col-md-6 item-box">
                <div className="item-min">
                  <div className="top-item">
                    <img
                      src={item1}
                      className="float-left"
                      alt=""
                    />
                    <span className="float-left">
                      Your current daily rewards:
                    </span>
                  </div>
                  <strong>+</strong>
                  <strong id="userPercentRate">0</strong>
                  <strong>%</strong>
                </div>
              </div>
              <div className="col-md-6 item-box">
                <div className="item-min bg-min">
                  <div className="top-item">
                    <img
                      src={item2}
                      className="float-left mob-min"
                      alt=""
                    />
                    <div className="list float-left">
                      <p className="mb-0">
                        Basic Rewards:+1%
                        <br />
                        Hold-bonus: <b>+</b>
                        <b id="holdPercentRate">0</b>
                        <b>%</b>
                        <br />
                        Contract bonus: <b>+</b>
                        <b>0.0%</b>
                        <b
                          id="contractBonusRate"
                          style={{ visibility: "hidden" }}
                        >
                          0
                        </b>
                        <br />
                        Leader bonus: <b>+</b>
                        <b id="leaderBonusRate">0</b>
                        <b>%</b>
                        <br />
                        Community bonus : <b>+</b>
                        <b id="communityBonusRate">0</b>
                        <b>%</b>
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <hr />
              </div>
              <div className="col-md-6 item-box">
                <div className="item-min">
                  <div className="top-item">
                    <span className="float-left">
                      {" "}
                      Available withdraw balance
                    </span>
                  </div>
                  <div className="egs">
                    <img src="assets/img/icon/item-3.png" alt="" />
                    <strong id="userAvailable">0</strong>
                  </div>
                </div>
              </div>
              <div className="col-md-6 item-box">
                <div className="item-min bg-right">
                  <div className="top-item text-center">
                    <span> Request withdraw</span>
                    <a href="#" id="withdrawbutton">
                      <i className="fa fa-link" /> WITHDRAW{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="with-text">
            <p>
              Click Withdraw button, and you will get instantly all your deposits
              earnings with a single transaction. Your personal hold-bonus will be
              reset. When withdrawing then you should have between 4-100 TRX on
              your wallet balance for blockchain fees. Withdraw fee increases
              gradually as the number of deposits increases.{" "}
            </p>
          </div>
          <hr />
          <div className="item-boxes mb-2">
            <div className="row">
              <div className="col-md-6 item-box mb-4">
                <div className="item-min">
                  <div className="top-item">
                    <span className="float-left"> Total Deposited</span>
                  </div>
                  <div className="egs">
                    <img src="assets/img/icon/item-3.png" alt="" />
                    <strong id="totalInvested">0</strong>
                  </div>
                </div>
              </div>
              <div className="col-md-6 item-box mb-4">
                <div className="item-min bg-right">
                  <div className="top-item">
                    <span className="float-right text-right">
                      Number of deposits:{" "}
                      <span
                        style={{ color: "#e7922e" }}
                        id="totalNumberOfDeposits"
                      >
                        0
                      </span>{" "}
                      <br />
                      <br />{" "}
                      <span style={{ display: "none" }} id="userCheckpoint" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 item-box">
                <div className="item-min">
                  <div className="top-item">
                    <span className="float-left">Total earned</span>
                  </div>
                  <div className="egs">
                    <img src="assets/img/icon/item-3.png" alt="" />
                    <strong id="totalEarned">0</strong>
                  </div>
                </div>
              </div>
              <div className="col-md-6 item-box">
                <div className="item-min bg-right">
                  <div className="top-item text-right">
                    <span className="float-right">Total withdrawn</span>
                  </div>
                  <div className="egs float-right">
                    <strong className="mr-2" id="totalWithdrawn">
                      0
                    </strong>
                    <img
                      style={{ transform: "scale(-1, 1)", marginRight: 0 }}
                      src="assets/img/icon/item-3.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="referral-link">
            <h5>COPY REFERRAL LINK</h5>
            <input
              type="text"
              id="reflinkdisplay"
              placeholder="loading"
              readOnly=""
            />
            <a href="javascript:copyFunction()" onclick="myFunction()">
              <i className="fa fa-link" /> COPY
            </a>
            <p>
              Share this link with your partners to get referral rewards <br />
              You should have active deposit to unlock affiliate bonuses!{" "}
            </p>
          </div>
          <div className="item-boxes ">
            <div className="row">
              <div className="col-md-6 item-box mb-2">
                <div className="item-min">
                  <div className="top-item">
                    <span className="float-left">Referral rewards:</span>
                  </div>
                  <div className="egs">
                    <img src="assets/img/icon/item-3.png" alt="" />
                    <strong id="refRewards">0</strong>
                  </div>
                </div>
              </div>
              <div className="col-md-6 item-box mb-2">
                <div className="item-min bg-right">
                  <div className="top-item pl-3">
                    <img
                      src="assets/img/icon/signal.png"
                      className="mt-3 signal float-left"
                      alt=""
                    />
                    <div
                      className="list float-left ml-0 pl-3"
                      style={{ width: "75%" }}
                    >
                      <p
                        className="mb-0 w-50  float-left"
                        style={{ lineHeight: 15 }}
                      >
                        1st Level: <b id="ref1"> 0 </b>
                        <br />
                        2nd Level: <b id="ref2">0</b>
                        <br />
                        3rd Level: <b id="ref3">0</b>
                        <br />
                        4th Level: <b id="ref4"> 0</b>
                        <br />
                        5th Level: <b id="ref5">0</b>
                        <br />
                        6th Level: <b id="ref6">0</b>
                      </p>
                      <p
                        className="mb-0 w-50  float-left"
                        style={{ lineHeight: 15 }}
                      >
                        7th Level: <b id="ref7">0 </b>
                        <br />
                        8th Level: <b id="ref8">0</b> <br />
                        9th Level: <b id="ref9">0</b> <br />
                        10th Level: <b id="ref10">0</b> <br />
                        11th Level: <b id="ref11">0</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 text-center">
                <a
                  href="https://t.me/Tron10x_Official"
                  target="_blank"
                  className="linked"
                >
                  <i className="fa fa-link" /> PROMO MATERIALS
                </a>
                {/*<a href="http://Tron10x.live/" target=_blank class="linked"><i class="fa fa-link"></i> Tron10x NETWORK</a>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default PersonalWallet;
