import React from 'react';
import { Link } from 'react-router-dom';
import cubeIcon from '../assets/img/icon/cube.png';
import commisionIcon from '../assets/img/icon/commision.png';
import affiliateMarketingIcon from '../assets/img/icon/affiliate-marketing.png';

const Affliated = ({className}) => {
  return (
    <React.Fragment>
      <div id="affiliate" className={className}>
  <div className="container">
    <div className="row">
      <div className="col-md-12 line-12">
        <div className="line" />
        <img src={cubeIcon} alt="" />
        <div className="line mr" />
      </div>
      <div className="col-md-6 ref-link">
        <div className="row">
          <div className="col-md-3  col-sm-3 col-12 ref-box">
            <a href="#" className="active">
              <img src={commisionIcon} alt="" />
              <strong>
                Referral <span className="d-block" />
              </strong>
            </a>
          </div>
          <div className="col-md-3 col-sm-3 col-6 ref-box">
            <a href="#">
              <span className="numb">01</span>
              <span className="text">Level</span>
             
              <strong className="d-block">10%</strong>
            </a>
          </div>
          <div className="col-md-3 col-sm-3 col-6 ref-box">
            <a href="#">
              <span className="numb">02</span>
              <span className="text">Level</span>
              <strong className="d-block">4%</strong>
            </a>
          </div>
          <div className="col-md-3 col-sm-3 col-6 ref-box">
            <a href="#">
              <span className="numb">03</span>
              <span className="text">Level</span>
              <strong className="d-block">2%</strong>
            </a>
          </div>
          <div className="col-md-3 col-sm-3 col-6 hide-sm ref-box">
            <a href="#">
              <span className="numb">07</span>
              <span className="text">Level</span>
              <strong className="d-block">0.5%</strong>
            </a>
          </div>
          <div className="col-md-3 col-sm-3 hide-sm col-6 ref-box">
            <a href="#">
              <span className="numb">06</span>
              <span className="text">Level</span>
              <strong className="d-block">0.5%</strong>
            </a>
          </div>
          <div className="col-md-3 col-sm-3 hide-sm col-6 ref-box">
            <a href="#">
              <span className="numb">05</span>
              <span className="text">Level</span>
              <strong className="d-block">1%</strong>
            </a>
          </div>
          <div className="col-md-3 col-sm-3 col-6 ref-box">
            <a href="#">
              <span className="numb">04</span>
              <span className="text">Level</span>
              <strong className="d-block">1%</strong>
            </a>
          </div>
          <div className="col-md-3 col-sm-3 hide-pc col-6 ref-box">
            <a href="#">
              <span className="numb">05</span>
              <span className="text">Level</span>
              <strong className="d-block">1%</strong>
            </a>
          </div>
          <div className="col-md-3 col-sm-3 hide-pc col-6 ref-box">
            <a href="#">
              <span className="numb">06</span>
              <span className="text">Level</span>
              <strong className="d-block">0.5%</strong>
            </a>
          </div>
          <div className="col-md-3 col-sm-3 hide-pc col-6 ref-box">
            <a href="#">
              <span className="numb">07</span>
              <span className="text">Level</span>
              <strong className="d-block">0.5%</strong>
            </a>
          </div>
          <div className="col-md-3 col-sm-3 col-6 ref-box">
            <a href="#">
              <span className="numb">08</span>
              <span className="text">Level</span>
              <strong className="d-block">0.4%</strong>
            </a>
          </div>
          <div className="col-md-3 col-sm-3 col-6 ref-box">
            <a href="#">
              <span className="numb">09</span>
              <span className="text">Level</span>
              <strong className="d-block">0.3%</strong>
            </a>
          </div>
          <div className="col-md-3 col-sm-3 col-6 ref-box">
            <a href="#">
              <span className="numb">10</span>
              <span className="text">Level</span>
              <strong className="d-block">0.2%</strong>
            </a>
          </div>
          <div className="col-md-3 col-sm-3 col-12 ref-box">
            <a href="#">
              <span className="numb">11</span>
              <span className="text">Level</span>
              <strong className="d-block">0.1%</strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="program">
    <div className="row m-0">
      <div className="col-md-6" />
      <div className="col-md-6  pr-0">
        <div className="program-box">
          <div className="program-text">
            <img src={affiliateMarketingIcon} alt="" />
            <h5>Affiliate Program</h5>
            <p>
              An affiliate program designed to suit very large teams can
              generate a significant increase in total revenue. Referral rewards
              instantly come out of the smart contract into your main wallet
              where only You have your private key. <br />
              Note: All Affiliate revenue is automatically distributed to
              wallets.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </React.Fragment>
  )
}

export default Affliated
