import React from 'react';
import cubeIcon from '../assets/img/icon/cube.png';
import paperIcon from '../assets/img/paper.png';
import highIcon from '../assets/img/icon/high.svg';
import handIcon from '../assets/img/icon/hand.svg';
import incentiveIcon from '../assets/img/icon/incentive.svg';
import moneyIcon from '../assets/img/icon/money.svg';
import bonusIcon from '../assets/img/icon/bonus.svg';

const CommunityProgram = ({className}) => {
  return (
    <React.Fragment>
      <div id="whats" className={className}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-3 line-12">
              <div className="line"></div>
              <img src={cubeIcon} alt="" />
              <div className="line mr"></div>
            </div>
            <div className="col-md-12 default-title">
              <h3>Communıty Program</h3>
            </div>
            <div className="col-md-4 what-box">
              <div className="what-min">
                <img src={cubeIcon} alt="" />
                <span>Minimum contribution amount:</span>
                <strong>100 TRX</strong>
              </div>
            </div>
            <div className="col-md-4 what-box">
              <div className="what-min">
                <img src={cubeIcon} alt="" />
                <span>Maximum contribution:</span>
                <strong>4,000,000 TRX</strong>
              </div>
            </div>
            <div className="col-md-4 what-box">
              <div className="what-min">
                <img src={cubeIcon} alt="" />
                <span>Total Rewards:</span>
                <strong>200%</strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="papers" className={className}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-5 line-12">
              <div className="line"></div>
              <img src={cubeIcon} alt="" />
              <div className="line mr"></div>
            </div>

            <div className="col-md-12 mb-4 mt-2 default-title">
              <h3></h3>
            </div>
            <div className="col-md-6 paper-left">
              <img src={paperIcon} className="img-fluid" alt="" />
            </div>
            <div className="col-md-6 paper-right">
              <div className="row">
                <div className="col-md-12 paper-box">
                  <div className="paper-min">
                    <img src={highIcon} style={{ transform: 'rotate(90deg)' }} width="30" alt="" />
                    <h4>BASIC REWARDS RATE:</h4>
                    <p>Earn +1% every 24 hours - Tron10x smart contract calculates the rewards up for every contribution made since the date of creation of the smart contract.</p>
                  </div>
                </div>
                <div className="col-md-6 paper-box">
                  <div className="paper-min">
                    <img src={handIcon} width="30" alt="" />
                    <h4>PERSONAL HOLD-BONUS:</h4>
                    <p>When you do not withdraw money, your rewards rate increases by 0.1% every 24 hours. ( Max 1%) </p>
                  </div>
                </div>
                <div className="col-md-6 paper-box">
                  <div className="paper-min">
                    <img src={incentiveIcon} width="30" alt="" />
                    <h4>LEADER BONUS:</h4>
                    <p>Earn +0.1% additional fee every time the platform distributes 1 billion trx in referral bonus and rewards (max 0.5%) </p>
                  </div>
                </div>
                <div className="col-md-6 paper-box">
                  <div className="paper-min">
                    <img src={moneyIcon} width="30" alt="" />
                    <h4>COMMUNITY BONUS:</h4>
                    <p>Earn +0.1 additional reward for every 10 million active users on the platform (Max 0.5%)</p>
                  </div>
                </div>
                <div className="col-md-6 paper-box">
                  <div className="paper-min">
                    <img className="bonus" src={bonusIcon} width="30" alt="" />
                    <h4>CONTRACT TOTAL AMOUNT BONUS:</h4>
                    <p>Get + 0.2% additional reward for every 1 billion TRX on Smart Contract Balance. (Max %1)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CommunityProgram;
