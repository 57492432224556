import React from 'react';
import cubeWhiteIcon from '../assets/img/icon/cube-white.png';
import downloadIcon from '../assets/img/icon/download_(1).png';
import documentIcon from '../assets/img/icon/document.png';
import investmentIcon from '../assets/img/icon/investment.png';

const Works = ({ }) => {
  const data = [
    {
      img: cubeWhiteIcon,
      title: "Get TRON (TRX)",
      description: "You can easily acquire TRX from the most popular platforms. After purchasing TRX, you can transfer it to your personal wallet, and then transfer it to Tron10x smart-contract address."
    },
    {
      img: downloadIcon,
      title: "Download the wallet app for Browser or Mobile",
      description: "We recommend using: TronLink / TokenPocket or Klever mobile app."
    },
    {
      img: documentIcon,
      title: "Transfer TRX to Smart Contract",
      description: "Send TRX to our Smart Contract"
    },

    {
      img: investmentIcon,
      title: "Start Earning",
      description: "Congratulations. You have joined the unique world of Tron10x. You can find all your personal information on Wallet Statistics page and you can withdraw your money from this screen whenever you want."
    }
  ];

  return (
    <>
      {/* <div id="downloads" className={{}}>
        <div className="white-dot"></div>
        <div className="blue-dot"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 down-text-left">
              <p className="mb-0">
                <span>How</span> it works?
              </p>
            </div>
            <div className="col-md-6 down-text-right">
              <p className="mb-0"></p>
            </div>
            {data.map((item, index) => (
              <div key={index + 1} className="col-md-3 download-box">
                <div className="download-min">
                  <div className="circle d-flex align-items-center justify-content-center p-2">
                    <img src={item.img} alt="" />
                  </div>
                  <div className="top-title">
                    <hr />
                    <div className="left-title px-2">0{index + 1}</div>
                    <div className="right-title text-center">
                      <strong className='text-center'>{item.title}</strong>
                    </div>
                  </div>
                  <div className="title-content">
                    <hr />
                    <p className='px-2 text-justify'>
                      {item.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      <section className="roadmap" id="roadmap">
  <div className="transition-gradient-after adjust-top" />
  <div className="container">
    <div className="dots-vert" />
    <div className="Heading">
      <p className="mb-0 text-secondary">
        <span>How</span> it works?
      </p>
    </div>
    <div className="row clearfix">
      <div className='col-md-2'/>
      <div className="col-lg-10">
        <div className="container-fluid">
          <div className="row clearfix left">
            <div className="col-lg-4 box left first reveal">
              <div className="dots pull-right circle d-flex align-items-center justify-content-center p-2">
                <img src={cubeWhiteIcon} alt="cubeWhiteIcon" style={{ filter: 'invert(15%) sepia(52%) saturate(4428%) hue-rotate(330deg) brightness(98%) contrast(95%)' }} />
              </div>
              <br />
              <p>
                <span className="heading green">1</span>
              </p>
              <br />  <br />
              <div className="milestones vertical-line right">
                <b className="text-center w-100 fw-bold">Get TRON (TRX)</b>
                <br />
                <span className="text-justify">You can easily acquire TRX from the most popular platforms. After purchasing TRX, you can transfer it to your personal wallet, and then transfer it to Tron10x smart-contract address.</span>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="lines first">
                <div className="roadmap-line active active" />
                <div className="roadmap-line active" />
              </div>
            </div>
            <div className="col-lg-4 box right first reveal">
              <div className="dots pull-left circle d-flex align-items-center justify-content-center p-2">
                <img src={downloadIcon} alt="downloadIcon" style={{ filter: 'invert(15%) sepia(52%) saturate(4428%) hue-rotate(330deg) brightness(98%) contrast(95%)' }} />
              </div>
              <br />
              <p>
                <span className="heading green">2</span>
              </p>
              <br />  <br />
              <div className="milestones vertical-line right">
                <b className="text-center w-100 fw-bold">Download the wallet app for Browser or Mobile</b>
                <br />
                <span className="text-justify">We recommend using: TronLink / TokenPocket or Klever mobile app.</span>
              </div>
            </div>
          </div>
          <div className="row clearfix left">
            <div className="col-lg-4 box left second reveal">
              <div className="dots pull-right circle d-flex align-items-center justify-content-center p-2">
                <img src={documentIcon} alt="documentIcon" style={{ filter: 'invert(15%) sepia(52%) saturate(4428%) hue-rotate(330deg) brightness(98%) contrast(95%)' }} />
              </div>
              <br />
              <p>
                <span className="heading green">3</span>
              </p>
              <br />  <br />
              <div className="milestones vertical-line right">
                <b className="text-center w-100 fw-bold">Transfer TRX to Smart Contract</b>
                <br />
                <span className="text-justify">Send TRX to our Smart Contract</span>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="lines second">
                <div className="roadmap-line active" />
                <div className="roadmap-line active" />
              </div>
            </div>
            <div className="col-lg-4 box right second reveal">
              <div className="dots pull-left circle d-flex align-items-center justify-content-center p-2">
                <img src={investmentIcon} alt="investmentIcon" style={{ filter: 'invert(15%) sepia(52%) saturate(4428%) hue-rotate(330deg) brightness(98%) contrast(95%)' }} />
              </div>
              <br />
              <p>
                <span className="heading green">4</span>
              </p>
              <br />  <br />
              <div className="milestones vertical-line right">
                <b className="text-center w-100 fw-bold">Start Earning</b>
                <br />
                <span className="text-justify">Congratulations. You have joined the unique world of Tron10x. You can find all your personal information on Wallet Statistics page and you can withdraw your money from this screen whenever you want.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



    </>
  );
};

export default Works;
